export default {
  'userGroup.name': 'Nhóm người dùng',
  'userGroup.title': 'Danh sách nhóm người dùng',

  'userGroup.table.code': 'Mã nhóm',
  'userGroup.table.group': 'Nhóm người dùng',
  'userGroup.table.groupInfo': 'Thông tin nhóm',
  'userGroup.table.createAt': 'Ngày tạo',
  'userGroup.table.system': 'Ứng dụng được phân quyền',
  'userGroup.table.action': 'Hành động',
  'userGroup.table.description': 'Mô tả',

  'userGroup.arrayAction.delete.title': 'Xác nhận xóa nhóm người dùng',
  'userGroup.arrayAction.delete.content':
    'Các nhóm người dùng được chọn sẽ bị xóa và không thể khôi phục.',

  'userGroupInfo.name': 'Thông tin nhóm người dùng',
  'userGroupInfo.list.user': 'Danh sách người dùng',
  'userGroupInfo.table.userName': 'Tên đăng nhập',
  'userGroupInfo.table.fullName': 'Tên đầy đủ',
  'userGroupInfo.table.phoneNumber': 'Số điện thoại',
  'userGroupInfo.table.dayOfBirth': 'Ngày sinh',
  'userGroupInfo.table.email': 'Email',

  'userGroupAdd.name': 'Thêm nhóm người dùng',
  'userGroupEdit.name': 'Chỉnh sửa nhóm người dùng',
  'userGroupAddAndEdit.system.placeholder': 'Vui lòng chọn ứng dụng được phân quyền',
  'userGroupAddAndEdit.delete.title': 'Xác nhận loại bỏ tài khoản',
  'userGroupAddAndEdit.delete.content': 'Bạn có muốn loại bỏ tài khoản khỏi nhóm người dùng ?',

  'userGroupAddAndEditModal.create': 'Thêm người dùng vào nhóm',
};
