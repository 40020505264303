export default {
  'login.lock.account.title': 'Lock account',
  'login.lock.account.notification':
    'Because you enter the wrong OTP code 5 times, your account has been locked.Please contact the support staff for more information.',
  'login.check.captcha': 'Please check the CAPTCHA code',
  'login.button.account': 'Log in',
  'login.button.mobile': 'Get OTP code',
  'login.button.by.account': 'Log in with an account',
  'login.button.by.mobile': 'Log in with phone numbers',
  'login.button.contact.support': 'Contact support',
  'login.title': 'Log in',
  'login.userName': 'Username. Ex: someone@example',
  'login.password': 'Password',
  'login.account.error': 'Wrong username or password.',
  'login.mobile.error': 'Wrong phone number',
  'login.remember': 'Remember me',
  'login.forgot.password': 'Forgot password?',
  'login.required.username': 'Please enter username',
  'login.required.password': 'Please enter password',

  // Update verify login
  'login.verify': 'Two-factor authentication',
  'login.verify.app': 'using the authenticator app',
  'login.verify.desc': 'Please enter your 6-digit backup code',
  'login.verify.remember': 'Don’t ask again on device',
  'login.verify.another': 'Try another method',
  'login.required.verify': 'Please enter the backup code',
  'modal.change.title': 'Choose another authentication method',
  'modal.change.app.label': 'Authenticator App',
  'modal.change.app.desc': 'Use the verification code from the connected app',

  'modal.change.code.label': 'Backup Code',
  'modal.change.code.desc': 'Use a previously provided backup code',
  'login.verify.code': 'using a backup code',
  'login.verify.back.login': 'Back to login page',
  'login.verify.version': 'version',
  'login.verify.code.pl': 'Enter your 8-digit backup code',
  'login.required.code.overlength': 'Backup code can be a maximum of 50 characters',
  'login.required.code.invalidCharacter': 'Backup code contains invalid characters',

  'login.error.contact': 'Contact',
  'login.error.title': 'Unable to Login',
  'login.error.desc':
    'We cannot verify your account because you have exhausted your authentication methods. Please contact the system administrator to recover your account.',
  'login.validate.code': 'The backup code must be numeric and have a maximum of 50 characters',
  //
  'login.error.invalid.password': 'Incorrect password. You have {num} attempts left.',
  'login.error.invalid.otp': 'Invalid verification code. You have {num} attempts left.',
  'login.error.modal.title': 'Account Locked',
  'login.error.modal.content.1':
    'Your account has been locked due to too many incorrect password attempts. Please contact the system administrator for assistance in recovering your account.',
  'login.error.modal.content.2':
    'Your account has been locked due to too many incorrect verification code attempts. Please contact the system administrator for assistance in recovering your account.',
  'login.error.modal.content.3':
    'Your account has been locked because all authentication methods have been exhausted. Please contact the system administrator for assistance in recovering your account.',
  'login.error.modal.content.4':
    'Your account has been locked by the system. Please contact the system administrator for assistance in recovering your account.',
};
