/* eslint-disable no-unused-vars */
import RoleEntity from '@modules/roles/entity';

class PermissionsEntity {
  resource: string = '';

  code: string = '';

  name: string = '';

  permissions: RoleEntity[] = [];

  constructor(permissions: Partial<PermissionsEntity>) {
    if (!permissions) {
      return;
    }
    Object.assign(this, permissions);
  }

  static createListPermissions(listPermissions: Array<Partial<PermissionsEntity>>) {
    if (!Array.isArray(listPermissions)) {
      return [];
    }
    return listPermissions.map((permissions: Partial<PermissionsEntity>) => {
      return new PermissionsEntity(permissions);
    });
  }
}

export enum PermissionEnum {
  ApplicationsCreate = 'ApplicationsCreate',
  ApplicationsDelete = 'ApplicationsDelete',
  ApplicationsSort = 'ApplicationsSort',
  ApplicationsUpdate = 'ApplicationsUpdate',
  ApplicationsView = 'ApplicationsView',
  ApplicationsViewDetail = 'ApplicationsViewDetail',

  GroupsCreate = 'GroupsCreate',
  GroupsDelete = 'GroupsDelete',
  GroupsUpdate = 'GroupsUpdate',
  GroupsView = 'GroupsView',
  GroupsViewDetail = 'GroupsViewDetail',

  UsersSystemsCreate = 'UsersSystemsCreate',
  UsersSystemsDelete = 'UsersSystemsDelete',
  UsersSystemsUpdate = 'UsersSystemsUpdate',
  UsersSystemsView = 'UsersSystemsView',
  UsersSystemsViewDetail = 'UsersSystemsViewDetail',
  UsersSystemsUnlock = 'UsersSystemsUnlock',

  UsersCreate = 'UsersCreate',
  UsersDelete = 'UsersDelete',
  UsersUpdate = 'UsersUpdate',
  UsersView = 'UsersView',
  UsersViewDetail = 'UsersViewDetail',
}

export default PermissionsEntity;
