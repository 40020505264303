export default {
  'login.lock.account.title': 'Khóa tài khoản',
  'login.lock.account.notification':
    'Do bạn nhập sai mã OTP 5 lần nên tài khoản của bạn đã bị khóa. Vui lòng liên lạc nhân viên hỗ trợ để biết thêm thông tin.',
  'login.check.captcha': 'Vui lòng kiểm tra mã captcha',
  'login.button.account': 'Đăng nhập',
  'login.button.mobile': 'Nhận mã OTP',
  'login.button.by.account': 'Đăng nhập bằng tài khoản',
  'login.button.by.mobile': 'Đăng nhập bằng số điện thoại',
  'login.button.contact.support': 'Liên lạc hỗ trợ',
  'login.title': 'Đăng nhập',
  'login.userName': 'Tên đăng nhập. Vd: someone@example',
  'login.password': 'Mật khẩu',
  'login.account.error': 'Sai tên đăng nhập hoặc mật khẩu.',
  'login.mobile.error': 'Sai số điện thoại',
  'login.remember': 'Ghi nhớ đăng nhập',
  'login.forgot.password': 'Quên mật khẩu?',
  'login.required.username': 'Vui lòng nhập tên đăng nhập',
  'login.required.password': 'Vui lòng nhập mật khẩu',

  // Update verify login
  'login.verify': 'Xác thực hai bước',
  'login.verify.app': 'bằng ứng dụng xác thực',
  'login.verify.desc': 'Vui lòng nhập mã dự phòng 6 chữ số của bạn',
  'login.verify.remember': 'Không hỏi lại trên thiết bị',
  'login.verify.another': 'Thử cách khác',
  'login.required.verify': 'Vui lòng nhập mã dự phòng',
  'modal.change.title': 'Chọn một cách xác thực khác',
  'modal.change.app.label': 'Ứng dụng xác thực',
  'modal.change.app.desc': 'Sử dụng mã xác thực trên ứng dụng đã kết nối',

  'modal.change.code.label': 'Mã dự phòng',
  'modal.change.code.desc': 'Sử dụng mã dự phòng đã được cấp',
  'login.verify.code': 'bằng mã dự phòng',
  'login.verify.back.login': 'Quay lại trang đăng nhập',
  'login.verify.version': 'phiên bản',
  'login.verify.code.pl': 'Nhập một mã dự phòng 8 chữ số của bạn',
  'login.required.code.overlength': 'Mã dự phòng tối đa 50 ký tự',
  'login.required.code.invalidCharacter': 'Mã dự phòng không hợp lệ',

  'login.error.contact': 'Liên hệ',
  'login.error.title': 'Không thể đăng nhập',
  'login.error.desc':
    'Chúng tôi không thể xác minh tài khoản của bạn vì bạn đã sử dụng hết cách thức xác thực của mình. Vui lòng liên hệ quản trị viên hệ thống để khôi phục lại tài khoản.',
  'login.validate.code': 'Mã dự phòng là số và có tối đa 50 kí tự',

  //
  'login.error.invalid.password': 'Mật khẩu không đúng. Bạn còn {num} lần nữa.',
  'login.error.invalid.otp': 'Mã xác thực không hợp lệ. Bạn còn {num} lần nữa.',
  'login.error.modal.title': 'Tài khoản đã bị khóa',
  'login.error.modal.content.1':
    'Tài khoản của bạn đã bị khóa do nhập sai mật khẩu quá số lần cho phép. Vui lòng liên hệ với quản trị viên hệ thống để được hỗ trợ khôi phục tài khoản.',
  'login.error.modal.content.2':
    'Tài khoản của bạn đã bị khóa do nhập sai mã xác thực quá số lần cho phép. Vui lòng liên hệ với quản trị viên hệ thống để được hỗ trợ khôi phục tài khoản.',
  'login.error.modal.content.3':
    'Tài khoản của bạn đã bị khóa do đã sử dụng hết cách thức xác thực. Vui lòng liên hệ với quản trị viên hệ thống để được hỗ trợ khôi phục tài khoản.',
  'login.error.modal.content.4':
    'Tài khoản của bạn đã bị khóa bởi hệ thống. Vui lòng liên hệ với quản trị viên hệ thống để được hỗ trợ khôi phục tài khoản.',
};
