export default {
  'userGroup.name': 'User Group',
  'userGroup.title': 'List of group users',

  'userGroup.table.code': 'Group Code',
  'userGroup.table.group': 'User Group',
  'userGroup.table.groupInfo': 'Group Information',
  'userGroup.table.createAt': 'Creation Date',
  'userGroup.table.system': 'Authorized Systems',
  'userGroup.table.action': 'Action',
  'userGroup.table.description': 'Description',

  'userGroup.arrayAction.delete.title': 'Confirm User Group Deletion',
  'userGroup.arrayAction.delete.content':
    'The selected user groups will be deleted and cannot be recovered.',

  'userGroupInfo.name': 'User Group Information',
  'userGroupInfo.list.user': 'User List',
  'userGroupInfo.table.userName': 'Username',
  'userGroupInfo.table.fullName': 'Full Name',
  'userGroupInfo.table.phoneNumber': 'Phone Number',
  'userGroupInfo.table.dayOfBirth': 'Date of Birth',
  'userGroupInfo.table.email': 'Email',

  'userGroupAdd.name': 'Add User Group',
  'userGroupEdit.name': 'Edit User Group',
  'userGroupAddAndEdit.system.placeholder': 'Please enter authorized systems',
  'userGroupAddAndEdit.delete.title': 'Confirm Account Removal',
  'userGroupAddAndEdit.delete.content': 'Do you want to remove the account from the user group?',

  'userGroupAddAndEditModal.create': 'Add Users to Group',
};
