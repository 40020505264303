import './style.scss';

import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { UserBaseSelector } from '@modules/authentication/profileStore';
import { ETypeDevice, useDeviceType } from '@shared/hook/useDeviceType';

import TitleUnderline from '../TitleUnderline';
import AccountAvatar from './components/AccounAvatar';
import AccountInfoItem, { IAccountInfoItem } from './components/AccountInfoItem';
import AccountModal, { IModalInfo } from './components/AccountModal';
import AvatarModal, { IAvatarModal } from './components/AvatarModal';

const AccountInfo: React.FC = () => {
  const user = useSelector(UserBaseSelector);
  const [modal, setModal] = useState<IModalInfo>({ isOpen: false });
  const [avatarModal, setAvatarModal] = useState<IAvatarModal>({ isVisible: false });
  const device = useDeviceType();

  const rightInfo: IAccountInfoItem[] = [
    {
      label: 'profile.info.username',
      value: user?.username ?? '',
    },
    {
      label: 'profile.info.phoneNumber',
      value: user?.phoneNumber ?? '',
    },
    {
      label: 'profile.info.email',
      value: user?.email ?? '',
    },
  ];

  return (
    <div className="account-info">
      <div className="main-setting">
        {device === ETypeDevice.DESKTOP && <TitleUnderline text="profile.menu.accountInfo" />}
        <div className="account-info-inner">
          <div className="account-info-left">
            <AccountAvatar
              name={user?.fullName}
              imageUrl={user?.avatar}
              onClick={() => setAvatarModal({ isVisible: true, dataEdit: user })}
            />
            <AccountInfoItem
              label="profile.info.name"
              value={user?.fullName}
              onClick={() => setModal({ isOpen: true, type: 'name' })}
            />
            <AccountInfoItem
              label="adminAccount.dayOfBirth"
              value={user?.dayOfBirth ? dayjs(user?.dayOfBirth).format('DD/MM/YYYY') : ''}
              onClick={() => setModal({ isOpen: true, type: 'dayOfBirth' })}
            />
          </div>
          <div className="account-info-right">
            {rightInfo.map((item: IAccountInfoItem, idx: number) => (
              <AccountInfoItem key={idx} className={'normal-item'} {...item} />
            ))}
          </div>
        </div>
        <AccountModal modal={modal} onClose={() => setModal({ isOpen: false })} />
        <AvatarModal modal={avatarModal} setModal={setAvatarModal} />
      </div>
    </div>
  );
};

export default React.memo(AccountInfo);
