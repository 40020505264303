export default {
  'profile.role': 'Vai trò',
  'profile._password': 'Mật khẩu',
  'profile.change.password.title': 'Thay đổi mật khẩu',
  'profile.newPassword': 'Mật khẩu mới',
  'profile.newPassword.required.1': 'Vui lòng nhập mật khẩu mới',
  'profile.newPassword.required.2':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'profile.confirm.newPassword': 'Xác nhận mật khẩu mới',
  'password.not.match': 'Mật khẩu không khớp',
  'profile.name': 'Hồ sơ',
  'profile.oldPassword': 'Mật khẩu cũ',
  'profile.oldPassword.required.1': 'Vui lòng nhập mật khẩu cũ',
  'profile.oldPassword.required.2':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'profile.title': 'Thông tin cơ bản',
  'users.nfcCode': 'CMND/CCCD',
  'profile.edit': 'Chỉnh sửa hồ sơ',
  'profile.back': 'Quay lại',
  'profile.head.center.text': 'Tài khoản và cài đặt',
  'profile.menu.title': 'Thiết lập',
  'profile.menu.accountInfo': 'Thông tin tài khoản',
  'profile.menu.security': 'Mật khẩu và bảo mật',
  'profile.menu.security.title': 'Đăng nhập & khôi phục',
  'profile.menu.security.text':
    'Quản lý mật khẩu, hoạt động đăng nhập và phương thức khôi phục của bạn.',
  'profile.menu.security.feature.pw.title': 'Thay đổi mật khẩu',
  'profile.menu.security.feature.pw.text': 'Chưa thay đổi',
  'profile.menu.security.feature.veri.title': 'Xác thực hai bước',
  'profile.menu.security.feature.veri.text': 'Tính năng Xác minh 2 bước đã tắt',
  'profile.menu.security.feature.activity.title': 'Hoạt động đăng nhập',
  'profile.menu.info.name': 'Họ và tên',
  'profile.menu.info.birthday': 'Ngày sinh',
  'profile.menu.info.username': 'Tên đăng nhập',
  'profile.menu.info.phoneNumber': 'Số điện thoại',
  'profile.menu.info.email': 'Email',
  'profile.menu.info.permission': 'Phân quyền',
  'profile.verification.page.title': 'Xác thực hai bước',
  'profile.verification.add.new.app.title': 'Thiết lập xác thực hai bước qua ứng dụng ',
  'profile.verification.app.info.page.title': 'Ứng dụng xác thực',
  'profile.verification.backup.code.page.title': 'Mã dự phòng',
  'profile.login.activity.page.title': 'Hoạt động đăng nhập',
  'profile.verification.page.action.title': 'Tính năng xác thực hai bước của bạn đang {turn}',
  'profile.verification.page.action.text':
    'Hãy ngăn chặn người lạ truy cập vào tài khoản của bạn bằng một lớp bảo mật nữa.',
  'profile.verification.page.action.btn.on': 'Bật',
  'profile.verification.page.action.btn.off': 'Tắt',
  'profile.verification.page.list.title': 'Các hình thức xác thực',
  'profile.verification.page.list.app.label': 'Ứng dụng xác thực',
  'profile.verification.page.list.app.desc2': 'Không kết nối',
  'profile.verification.page.list.code.label': 'Mã dự phòng',
  'profile.verification.page.list.code.desc2.1': 'Không có',
  'profile.verification.page.list.code.desc2.2': 'Hết mã',
  'profile.verification.page.list.code.device': 'Còn {num} mã',
  'profile.verification.page.list.action.btn': 'Thêm mới',
  'profile.verification.page.popup.on.title':
    'Giờ đây, bạn được bảo vệ bằng tính năng xác minh 2 bước',
  'profile.verification.page.popup.on.content':
    'Khi đăng nhập, bạn sẽ được yêu cầu hoàn tất bước thứ hai an toàn nhất. Do đó, hãy đảm bảo rằng thông tin này luôn được cập nhật.',
  'profile.verification.page.popup.off.title': 'Tắt Xác minh 2 bước',
  'profile.verification.page.popup.off.content':
    'Khi tắt tính năng xác minh 2 bước, bạn cũng sẽ xóa lớp bảo mật bổ sung trong tài khoản của mình.',
  'profile.info.name': 'Họ và tên',
  'profile.info.idCard': 'CMND/CCCD',
  'profile.info.username': 'Tên đăng nhập',
  'profile.info.phoneNumber': 'Số điện thoại',
  'profile.info.email': 'Email',
  'profile.info.permission': 'Phân quyền',
  'profile.modal.avatar.title': 'Thay đổi ảnh đại diện',
  'profile.modal.avatar.des': 'Ảnh đại diện sẽ giúp bạn dễ dàng nhận diện.',
  'profile.modal.avatar.des.2': 'Định dạng hỗ trợ: PNG, JPG, WebP',
  'profile.modal.name.title': 'Thay đổi họ và tên ',
  'profile.modal.name.des': 'Họ và tên giúp xác định danh tính của bạn.',
  'profile.modal.idCard.title': 'Thay đổi CMND/CCCD',
  'profile.modal.idCard.des': 'Thông tin CMND/CCCD giúp hoàn thiện thông tin của bạn.',
  'profile.modal.dayOfBirth.title': 'Thay đổi ngày sinh',
  'profile.modal.dayOfBirth.des': 'Thông tin ngày sinh giúp hoàn thiện thông tin của bạn.',
  'profile.form.fullName': 'Họ và tên',
  'profile.form.fullName.required.space': 'Họ và tên không thể là một khoảng trắng',
  'profile.form.fullName.required.255': 'Họ và tên không vượt quá 255 kí tự',
  'profile.form.idCard': 'CMND/CCCD',
  'profile.form.idCard.required.255': 'CMND/CCCD không vượt quá 255 kí tự',
  'profile.form.fullName.pla': 'Nhập họ và tên',
  'profile.form.idCard.pla': 'Nhập CMND/CCCD',
  'profile.form.note': 'Đây trường thông tin bắt buộc',
  'profile.add.new.app.rule.label.1': 'Tải xuống ứng dụng xác thực',
  'profile.add.new.app.rule.value.1': 'Tải xuống Google Authenticator nếu bạn chưa cài đặt.',
  'profile.add.new.app.rule.label.2': 'Quét mã barcode/Mã QR hoặc sao chép khóa',
  'profile.add.new.app.rule.value.2':
    'Quét mã barcode/Mã QR này trong ứng dụng xác thực hoặc sao chép mã khóa và dán vào trong ứng dụng xác thực.',
  'profile.add.new.app.rule.label.3': 'Sao chép và nhập mã 6 chữ số',
  'profile.add.new.app.rule.value.3':
    'Sau khi quét mã barcode/Mã QR hoặc nhập khóa, ứng dụng xác thực của bạn sẽ tạo ra một mã 6 chữ số. Sao chép mã này và quay lại đây chọn “Xác nhận” để nhập vào ở bước tiếp theo.',
  'profile.add.new.app.qr.code.text': 'Mã QR',
  'profile.add.new.app.qr.code.copy.text': 'Sao chép',
  'profile.add.new.app.qr.code.copy.success': 'Sao chép mã thành công',
  'profile.add.new.modal.add.new.code.title': 'Thiết lập xác thực hai bước qua ứng dụng',
  'profile.add.new.modal.add.new.code.text-1':
    'Vui lòng nhập mã xác nhận trên ứng dụng bạn vừa kết nối.',
  'profile.add.new.modal.add.new.code.text-2': 'Nhập mã xác thực trên ứng dụng để hoàn tất',
  'profile.app.info.text-1':
    'Xác thực bằng cách lấy mã xác minh từ một ứng dụng xác thực. Ứng dụng này hoạt động ngay cả khi điện thoại của bạn không có kết nối mạng.',
  'profile.app.info.text-2':
    'Trước tiên, hãy tải ứng dụng Google Authenticator xuống từ Cửa hàng Google Play hoặc AppStore trên thiết bị iOS.',
  'profile.app.info.sub-title': 'Ứng dụng Authenticator của bạn',
  'profile.app.info.btn.update': 'Thay đổi',
  'profile.app.info.btn.plus': 'Thêm ứng dụng xác thực',
  'profile.app.info.modal.confirm.title': 'Xóa ứng dụng',
  'profile.app.info.modal.confirm.content':
    'Bạn sẽ không thể sử dụng những mã dự phòng này để đăng nhập.',
  'profile.app.info.backup-code.text':
    'Tải xuống mã dự phòng để nhập vào khi cần đăng nhập, Giữ các mã dự phòng này ở nơi an toàn nhưng có thể lấy được.',
  'profile.app.info.backup-code.sub-title': 'Mã dự phòng của bạn',
  'profile.app.info.backup-code.action.title': 'Còn {num} mã dự phòng',
  'profile.app.info.backup-code.action.btn.download': 'Tải xuống',
  'profile.app.info.backup-code.action.btn.refresh': 'Làm mới',
  'profile.app.info.backup-code.action.btn.plus': 'Thêm mã dự phòng',
  'profile.app.info.backup-code.modal.delete.title': 'Xóa mã dự phòng',
  'profile.app.info.backup-code.modal.delete.content':
    'Bạn sẽ không thể sử dụng những mã dự phòng này để đăng nhập.',
  'profile.app.info.backup-code.modal.add.title': 'Nhận bộ mã dự phòng mới',
  'profile.app.info.backup-code.modal.add.content':
    'Nếu bạn nhận được mã dự phòng mới, những mã cũ còn lại sẽ không sử dụng',
  'profile.login-activities.device.active': 'Thiết bị này',
  'profile.login-activities.device.quantity': '{num} thiết bị đã chọn',
  'profile.login-activities.action.select': 'Chọn thiết bị đăng xuất',
  'profile.login-activities.action.back': 'Quay lại',
  'profile.login-activities.action.confirm': 'Xác nhận',
  'profile.login-activities.title': 'Lần đăng nhập trên thiết bị khác',
  'profile.login-activities.detail.title.1': 'Hoạt động gần nhất',
  'profile.login-activities.detail.title.2': 'Lần đầu đăng nhập',
  'profile.login-activities.detail.modal.confirm.title': 'Đăng xuất thiết bị',
  'profile.login-activities.detail.modal.confirm.content':
    'Thao tác này sẽ ngăn không cho thiết bị mà bạn chỉ định truy cập vào Tài khoản của bạn nữa',
  'profile.modal.verify.account.title': 'Xác minh tài khoản',
  'profile.modal.verify.account.content':
    'Để tiếp tục, hãy xác minh tài khoản {name} bằng cách nhập mật khẩu của bạn',
  'profile.modal.verify.account.pw.pl': 'Mật khẩu',
  'profile.modal.verify.account.pw.required.1': 'Vui lòng nhập mật khẩu',
  'profile.modal.verify.account.pw.required.2':
    'Mật khẩu phải có ít nhất 8 ký tự, bao gồm chữ thường, chữ hoa, số và ký tự đặc biệt.',
  'profile.verification.page.list.app.no.device': 'Thêm ứng dụng',
  'profile.verification.page.list.code.no.device': 'Thêm mã',
  'profile.verification.page.delete.inform.title': 'Điều kiện xác thực hai bước',
  'profile.verification.page.delete.inform.content':
    'Bạn cần ít nhất một phương thức xác thực được kết nối để tiếp tục.',
  'profile.account.modal.avatar.title.avatar': 'Ảnh đại diện',
  'profile.account.modal.avatar.title.confirm': 'Xác nhận ảnh đại diện',
  'profile.account.modal.avatar.title.delete': 'Xóa ảnh đại diện',
  'profile.account.modal.avatar.desc': 'Ảnh đại diện sẽ giúp bạn dễ dàng nhận diện.',
  'profile.account.modal.avatar.required.capacity': 'Dung lượng không vượt quá 30MB',
  'profile.account.modal.avatar.delete.desc': 'Ảnh đại diện sẽ được thay đổi về ảnh mặc định',
  'profile.account.modal.avatar.btn.add': 'Thêm ảnh',
  'profile.account.modal.avatar.btn.update': 'Thay đổi',
  'profile.verification.page.modal.expired.title': 'Phiên làm việc đã hết hạn',
};
