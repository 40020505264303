export default {
  ApplicationsCreate: 'Create Application',
  ApplicationsDelete: 'Delete Application',
  ApplicationsSort: 'Sort Applications',
  ApplicationsUpdate: 'Update Application',
  ApplicationsView: 'View Application',
  ApplicationsViewDetail: 'View Application Details',
  GroupsCreate: 'Create User Group',
  GroupsDelete: 'Delete User Group',
  GroupsUpdate: 'Update User Group',
  GroupsView: 'View User Group',
  GroupsViewDetail: 'View User Group Details',

  UsersSystemsUpdate: 'Update Admin Account',
  UsersSystemsDelete: 'Delete Admin Account',
  UsersSystemsCreate: 'Create Admin Account',
  UsersSystemsView: 'View Admin Account',
  UsersSystemsViewDetail: 'View Admin Account Details',
  UsersSystemsUnlock: 'Unlock account',
  UsersUpdate: 'Update User Account',
  UsersView: 'View User Account',
  UsersViewDetail: 'View User Account Details',
  UsersCreate: 'Create User Account',
  UsersDelete: 'Delete User Account',

  'user.status.read': 'Get user activity status',
  'user.name.write': 'Edit user first and last name',
  'user.phone.read': 'Get user phone number',
  'user.id.read': 'Get user identification code',
  'user.email.read': 'Get user email',
  'user.address.read': 'Get user address',
  'user.name.read': 'Get user first and last name',
  'user.avatar.read': 'Get user avatar',
  'user.idcard.Read': 'Get user ID card or Citizen Card',
  'user.username.read': 'Get user username',
  'user.profile': 'Get user profile information',
};
