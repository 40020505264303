export default {
  'profile.role': 'Role',
  'profile._password': 'Password',
  'profile.change.password.title': 'Change Password',
  'profile.newPassword': 'New Password',
  'profile.newPassword.required.1': 'Please enter a new password',
  'profile.newPassword.required.2':
    'The password must be at least 8 characters long, including lowercase letters, uppercase letters, numbers, and special characters.',
  'profile.confirm.newPassword': 'Confirm New Password',
  'password.not.match': 'Passwords do not match',
  'profile.name': 'Profile',
  'profile.oldPassword': 'Old Password',
  'profile.oldPassword.required.1': 'Please enter the old password',
  'profile.oldPassword.required.2':
    'The password must be at least 8 characters long, including lowercase letters, uppercase letters, numbers, and special characters.',
  'profile.title': 'Basic Information',
  'users.nfcCode': 'ID Card',
  'profile.edit': 'Edit Profile',
  'profile.back': 'Go Back',
  'profile.head.center.text': 'Account and Settings',
  'profile.menu.title': 'Settings',
  'profile.menu.accountInfo': 'Account Information',
  'profile.menu.security': 'Password and Security',
  'profile.menu.security.title': 'Login & Recovery',
  'profile.menu.security.text': 'Manage your password, login activities, and recovery methods.',
  'profile.menu.security.feature.pw.title': 'Change Password',
  'profile.menu.security.feature.pw.text': 'Not changed yet',
  'profile.menu.security.feature.veri.title': 'Two-Step Verification',
  'profile.menu.security.feature.veri.text': 'Two-step verification is turned off',
  'profile.menu.security.feature.activity.title': 'Login Activity',
  'profile.menu.info.name': 'Full Name',
  'profile.menu.info.birthday': 'Date of Birth',
  'profile.menu.info.username': 'Username',
  'profile.menu.info.phoneNumber': 'Phone Number',
  'profile.menu.info.email': 'Email',
  'profile.menu.info.permission': 'Permission',
  'profile.verification.page.title': 'Two-Step Verification',
  'profile.verification.add.new.app.title': 'Set up two-step verification via app',
  'profile.verification.app.info.page.title': 'Authentication App',
  'profile.verification.backup.code.page.title': 'Backup Codes',
  'profile.login.activity.page.title': 'Login Activity',
  'profile.verification.page.action.title': 'Your two-step verification feature is {turn}',
  'profile.verification.page.action.text':
    'Prevent unauthorized access to your account with an extra layer of security.',
  'profile.verification.page.action.btn.on': 'Enable',
  'profile.verification.page.action.btn.off': 'Disable',
  'profile.verification.page.list.title': 'Verification Methods',
  'profile.verification.page.list.app.label': 'Authentication App',
  'profile.verification.page.list.app.desc2': 'Not connected',
  'profile.verification.page.list.code.label': 'Backup Codes',
  'profile.verification.page.list.code.desc2.1': 'Not available',
  'profile.verification.page.list.code.desc2.2': 'No codes left',
  'profile.verification.page.list.code.device': '{num} code(s) remaining',
  'profile.verification.page.list.action.btn': 'Add New',
  'profile.verification.page.popup.on.title': 'You are now protected with two-step verification',
  'profile.verification.page.popup.on.content':
    'When logging in, you will be required to complete the safest second step. Therefore, make sure this information is always up-to-date.',
  'profile.verification.page.popup.off.title': 'Disable Two-Step Verification',
  'profile.verification.page.popup.off.content':
    'Disabling two-step verification will also remove the additional security layer from your account.',
  'profile.info.name': 'Full Name',
  'profile.info.idCard': 'ID Card',
  'profile.info.username': 'Username',
  'profile.info.phoneNumber': 'Phone Number',
  'profile.info.email': 'Email',
  'profile.info.permission': 'Permission',
  'profile.modal.avatar.title': 'Change Profile Picture',
  'profile.modal.avatar.des': 'A profile picture helps others easily recognize you.',
  'profile.modal.avatar.des.2': 'Supported formats: PNG, JPG, WebP',
  'profile.modal.name.title': 'Change Full Name',
  'profile.modal.name.des': 'Your full name helps identify your identity.',
  'profile.modal.idCard.title': 'Change ID Card',
  'profile.modal.idCard.des': 'ID Card information helps complete your profile.',
  'profile.modal.dayOfBirth.title': 'Change Date of Birth',
  'profile.modal.dayOfBirth.des': 'Date of birth information helps complete your profile.',
  'profile.form.fullName': 'Full Name',
  'profile.form.idCard': 'ID Card',
  'profile.form.fullName.pla': 'Enter full name',
  'profile.form.idCard.pla': 'Enter ID Card',
  'profile.form.note': 'This is a required field',
  'profile.form.fullName.required.255': 'Full name must not exceed 255 characters',
  'profile.form.fullName.required.space': 'Full name cannot be just a space',
  'profile.form.idCard.required.255': 'ID card must not exceed 255 characters',
  'profile.add.new.app.rule.label.1': 'Download the authentication app',
  'profile.add.new.app.rule.value.1':
    "Download Google Authenticator if you haven't installed it yet.",
  'profile.add.new.app.rule.label.2': 'Scan the barcode/QR code or copy the key',
  'profile.add.new.app.rule.value.2':
    'Scan this barcode/QR code in the authentication app or copy the key and paste it into the authentication app.',
  'profile.add.new.app.rule.label.3': 'Copy and enter the 6-digit code',
  'profile.add.new.app.rule.value.3':
    "After scanning the barcode/QR code or entering the key, your authentication app will generate a 6-digit code. Copy this code and return here to select 'Confirm' to enter it in the next step.",
  'profile.add.new.app.qr.code.text': 'QR Code',
  'profile.add.new.app.qr.code.copy.text': 'Copy',
  'profile.add.new.app.qr.code.copy.success': 'Code copied successfully',
  'profile.add.new.modal.add.new.code.title': 'Set up two-step verification via app',
  'profile.add.new.modal.add.new.code.text-1':
    'Please enter the verification code from the app you just connected.',
  'profile.add.new.modal.add.new.code.text-2':
    'Enter the verification code from the app to complete',
  'profile.app.info.text-1':
    'Authenticate by retrieving a verification code from an authentication app. This app works even when your phone has no network connection.',
  'profile.app.info.text-2':
    'First, download the Google Authenticator app from the Google Play Store or App Store on your iOS device.',
  'profile.app.info.sub-title': 'Your Authenticator App',
  'profile.app.info.btn.update': 'Change',
  'profile.app.info.btn.plus': 'Add Authentication App',
  'profile.app.info.modal.confirm.title': 'Remove App',
  'profile.app.info.modal.confirm.content':
    'You will no longer be able to use these backup codes to log in.',
  'profile.app.info.backup-code.text':
    'Download backup codes to use when needed to log in. Keep these backup codes in a safe but accessible place.',
  'profile.app.info.backup-code.sub-title': 'Your Backup Codes',
  'profile.app.info.backup-code.action.title': '{num} backup code(s) remaining',
  'profile.app.info.backup-code.action.btn.download': 'Download',
  'profile.app.info.backup-code.action.btn.refresh': 'Refresh',
  'profile.app.info.backup-code.action.btn.plus': 'Add Backup Codes',
  'profile.app.info.backup-code.modal.delete.title': 'Delete Backup Codes',
  'profile.app.info.backup-code.modal.delete.content':
    'You will no longer be able to use these backup codes to log in.',
  'profile.app.info.backup-code.modal.add.title': 'Generate New Backup Codes',
  'profile.app.info.backup-code.modal.add.content':
    'If you generate new backup codes, the remaining old ones will no longer be usable.',
  'profile.login-activities.device.active': 'This Device',
  'profile.login-activities.device.quantity': '{num} device(s) selected',
  'profile.login-activities.action.select': 'Select Devices to Log Out',
  'profile.login-activities.action.back': 'Go Back',
  'profile.login-activities.action.confirm': 'Confirm',
  'profile.login-activities.title': 'Login from Other Devices',
  'profile.login-activities.detail.title.1': 'Most Recent Activity',
  'profile.login-activities.detail.title.2': 'First Login',
  'profile.login-activities.detail.modal.confirm.title': 'Log Out Device',
  'profile.login-activities.detail.modal.confirm.content':
    'This action will prevent the specified device from accessing your account.',
  'profile.modal.verify.account.title': 'Verify Account',
  'profile.modal.verify.account.content':
    'To proceed, verify the account {name} by entering your password.',
  'profile.modal.verify.account.pw.pl': 'Password',
  'profile.modal.verify.account.pw.required.1': 'Please enter your password.',
  'profile.modal.verify.account.pw.required.2':
    'The password must be at least 8 characters long, including lowercase, uppercase, numbers, and special characters.',
  'profile.verification.page.list.app.no.device': 'Add application',
  'profile.verification.page.list.code.no.device': 'Add code',
  'profile.verification.page.delete.inform.title': 'Two-Step Verification Conditions',
  'profile.verification.page.delete.inform.content':
    'You need at least one connected verification method to proceed.',
  'profile.account.modal.avatar.title.avatar': 'Avatar',
  'profile.account.modal.avatar.title.confirm': 'Confirm Avatar',
  'profile.account.modal.avatar.title.delete': 'Delete Avatar',
  'profile.account.modal.avatar.desc': 'An avatar will help you be easily recognized.',
  'profile.account.modal.avatar.required.capacity': 'The size should not exceed 30MB',
  'profile.account.modal.avatar.delete.desc': 'The avatar will be changed to the default image',
  'profile.account.modal.avatar.btn.add': 'Add Image',
  'profile.account.modal.avatar.btn.update': 'Update',
  'profile.verification.page.modal.expired.title': 'Session has expired',
};
