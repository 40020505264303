export default {
  'common.empty': ' ',
  'common.action': 'Hành động',
  'common.save': 'Lưu',
  'common.cancel': 'Hủy',
  'common.home': 'Trang chủ',
  'common.display': 'Hiển thị',
  'common.entries': 'hàng trong mỗi trang',
  'common.form.required': 'Vui lòng nhập',
  'common.delete': 'Xóa',
  'common.share': 'Chia sẻ',
  'common.listen': 'Nghe',
  'common.statusActive': 'Đang hoạt động',
  'common.add': 'Thêm',
  'common.addSchool': 'Đăng ký trường',
  'common.edit': 'Cập nhật',
  'common.all': 'Tất cả',
  'common.keyword': 'Từ khóa',
  'common.statusDeactive': 'Dừng hoạt động',
  'common.button.accept': 'Xác nhận',
  'common.button.resend.link': 'Gửi lại link',
  'common.button.return': 'Quay lại',
  'link.return.login': 'Quay lại trang đăng nhập',
  'common.answers': 'Danh sách đáp án',
  'common.valid.required': 'Vui lòng điền',
  'common.valid.whitespace': 'Vui lòng không nhập khoảng trắng',
  'common.valid.email': 'Phải đúng định dạng',
  'common.deviceType': 'Loại thiết bị',
  'common.email': 'Địa chỉ email',
  'common.update.user': 'Chỉnh sửa hố sơ',
  'common.update.change-password': 'Thay đổi mật khẩu',
  'common.yes': 'Đồng ý',
  'common.download': 'Tải xuống',

  'common.profile': 'Trang cá nhân',
  'common.forgotPassword': 'Quên mật khẩu',

  'common.volumn': 'Điều chỉnh âm lượng',
  'common.powerOn': 'Mở nguồn',
  'common.powerOff': 'Tắt nguồn',
  'common.refresh': 'Khởi động lại',
  'common.update': 'Cập nhật',
  'common.continue': 'Tiếp tục',
  'common.management': 'Quản lý',
  'common.formNote': 'Là những trường thông tin bắt buộc',
  'common.active': 'Đang hoạt động',
  'common.deactive': 'Dừng hoạt động',
  'common.status': 'Trạng thái',
  'common.statusNotApproval': 'Chưa duyệt',
  'common.statusReject': 'Từ chối',
  'common.invalid': 'không đúng định dạng.',
  'common.input.placeholder': 'Vui lòng nhập {label}',
  'common.status.param':
    '{status,select, 0 {Chưa phê duyệt} 1 {Đã phê duyệt} 2 {Từ chối} other {Không xác định}}',
  'common.status.new': 'Chưa phê duyệt',
  'common.status.approval': 'Đã phê duyệt',
  'common.approval': 'Phê duyệt',
  'common.file-back': 'Thu hồi',
  'common.back': 'Quay lại',
  'common.statusConnect': 'Đang kết nối',
  'common.statusDisconnect': 'Mất kết nối',
  'common.StatusNewsletterPlaying': 'Đang phát bản tin',
  'common.StatusNewsletterStop': 'Dừng phát bản tin',
  'common.goBack': 'Trở Về',
  'common.required.password': 'Vui lòng nhập mật khẩu',
  'common.info': 'Thông tin',
  'dashboard.area': 'Địa bàn',
  'dashboard.radio_device': 'Thiết bị phát thanh',
  'dashboard.mtc_device': 'Thiết bị điện tử công cộng',
  'dashboard.audio_news': 'Bản tin phát thanh',
  'dashboard.mtc_news': 'Bản tin điện tử công cộng',
  'area.placeholder': 'Vui lòng nhập tên địa bàn',
  'common.remove': 'Xóa',
  'common.play': 'Phát',
  'common.stt': 'STT',
  'common.detail': 'Chi tiết',
  'common.non.title': 'Hành động',
  'common.turnOn': 'Bật',
  'common.turnOff': 'Tắt',
  'common.pause': 'Dừng phát',
  'common.convert': 'Chuyển đổi',
  'common.using': 'Đang sử dụng',
  'common.notUse': 'Chưa sử dụng',
  'common.check': 'Lưu',
  'common.close': 'Đóng',
  'common.noti': 'Thông báo',
  'common.viewPlus': 'Xem thêm',
  'common.return': 'Quay lại',
  'common.search.placeholder': 'Nhập từ khóa để tìm kiếm',
  'common.eye': 'Hiển thị',
  'common.eyeOff': 'Ẩn',
  'common.hidden': 'Không hiển thị',
  'common.in.process': 'Đang tải lên',
  'common.logout': 'Đăng xuất',
  'common.logout.title': 'Xác nhận đăng xuất',
  'common.logout.content': 'Bạn có thực sự muốn đăng xuất không?',
  'common.change.password': 'Đổi mật khẩu',
  'common.report': 'Báo cáo',
  'common.page': 'Trang',
  'common.search': 'Tìm kiếm',
  'common.transactions': 'Kiểm kê',
  'common.product': 'Sản phẩm',
  'common.today': 'Hôm nay',
  'common.file': 'Xuất file',
  'common.checkAll': 'Chọn tất cả',
  'common.check-in': 'Đã checkin',
  'common.not-check-in': 'Chưa checkin',
  'common.LifeBuoy': 'Nhật ký checkin',
  'common.total': 'Tổng tiền',
  'common.month': 'tháng',
  'common.day': 'ngày',
  'common.file-edit': 'Package extension',
  'common.upload': 'Chọn file',
  'common.file-check-green': 'Duyệt',
  'common.file-check-red': 'Từ chối duyệt',
  'common.selected': '{rows} nội dung đã được chọn',
  'common.minus': 'Xóa',
  'common.modal.app': 'Truy cập dịch vụ',
  'common.modal.okText': 'Đi tới dịch vụ',
  'common.modal.text': 'Bạn đang được yêu cầu chuyển tới dịch vụ:',
  'common.upload-avatar': 'Chọn file',
  'profile.back': 'Trở về',
  'common-required-phone-country': 'Số điện thoại phải từ 5 đến 15 ký tự!',
  'common.done': 'Xong',
  'common.unlock': 'Mở khóa',
};
